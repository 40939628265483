import React, {useState, useRef, useEffect} from 'react';
import { css } from '@emotion/react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { color } from '../styles/theme';
import mq from '../styles/mediaquerys';
import navigation from '../../../data/navigation';
import Link from '../../link';
import { DropdownItem, DropdownList } from './plue_navigation';
import LinkIcon from '../assets/svg/link.svg';
import ChevronRightCircle from '../assets/svg/chevron-right-circle.svg';
import { IconButton } from '../styles/elements';
import SALogo from "../assets/logos/expo9_pri_wht_rgb.svg"
import PlueLoginButton from './plueLoginButton';
import Flags from '../../../data/raw/flags';

const Navigation = () => {

  const NavigationItemInner = ({title, icon, url, dropdownActive}) => {

    return (
      <div css={css`
        position: relative;
        padding: .75em 1.5em;
        margin: 0 0 0 1.5em;
      `}>
        <div
          css={css`
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: .8em;
            svg {
              width: 100%;
              height: auto;
              fill: ${color.grey400};
              transition: transform .6s ease-in-out;
              transform: ${dropdownActive ? "rotate(90deg)" : "rotate(0deg)"};
            }
          `}
        >
          {icon}
        </div>
        <p css={[css`
          font-weight: 600;
          font-size: 1em;
          letter-spacing: .01em;
          color: ${color.main_dark}; 
          text-align: left;
        `, url ? (
          css`
            &:after {
              content: "›";
              margin-left: 0.25em;
            }
          `
        ) : null]}>{title}</p>
      </div>
    )
  }

  const NavigationItemLink = ({el}) => {
    return (
      <Link
        to={el.url}
        css={css`
          text-decoration: none;
          display: block;
          &:last-of-type {
            margin-bottom: .1em;
          }
        `}
      >
        <NavigationItemInner
          icon={<LinkIcon />}
          title={el.title}
          url={el.url}
        />
      </Link>
    )
  }
  
  const NavigationItem = ({el, items}) => {
    const [dropdownActive, setDropdownActive] = useState(false);
    const [dropdownHeight, setDropdownHeight] = useState(0);
    const dropdown = useRef(null)
    

    const toggleDrawerItem = () => {
      setDropdownActive((dropdownActive) => !dropdownActive )      
    }

    useEffect(() => {
      if (dropdownActive) {
        setDropdownHeight(dropdown.current.scrollHeight) 
      } else {
        setDropdownHeight(0)
      }

    },[dropdownActive])

    return (
      <>
        <button
          onClick={toggleDrawerItem}
          css={css`
            border: none;
            background: none;
            cursor: pointer;
            position: relative;
            padding: 0;
            width: 100%;
            margin-bottom: .1em;
        `}>
          
          <NavigationItemInner
            icon={<ChevronRightCircle />}
            title={el.title}
            dropdownActive={dropdownActive}
          />
        </button>
        
        <div css={css`
          height: ${dropdownHeight + 'px'};
          overflow: hidden;
          transition: height 0.6s ease;
        `}>
          <NavigationItemList el={el} items={items} dropdown={dropdown} />
        </div>
      </>
    )
  }

  const NavigationItemList = ({el, items, dropdown}) => {

    return (
      <ul ref={dropdown} css={css`
        list-style: none;
        margin: 0;
        &:last-of-type {
          padding-bottom: 3em;
        }
      `}>
        {el.items.map((el, index) => (
          <li
            key={index}
            css={css`
              margin-bottom: 0;
              padding-left: 1em;
              background: ${el.background};
            `}
          >
            {el.url ? (
              <Link
                to={el.url}
                css={css`
                  text-decoration: none;
                `}
              >
                <DropdownItem el={el} items={items} />
              </Link>
            ) : <DropdownItem el={el} items={items} css={css`
              opacity: ${el.items && el.items.length > 0 ? 1 : .35};
            `}  />}

            {el.items && el.items.length > 0 ? (
              <DropdownList el={el} items={items} />
            ) : null}
          </li>
        ))}
      </ul>
    )
  }

  return (
    <section>
      <ul css={css`
        list-style: none;
        margin: 2rem 0 0;
        padding-top: 2rem;
        border-top: 1px solid ${color.grey200};
      `}>
        {navigation.header.navigation.map((el, index) => (
          <li key={index} css={css`
            margin-bottom: 0;
          `}>
            {el.items && el.items.length > 0 ? <NavigationItem el={el} items={navigation.header.navigation} /> : <NavigationItemLink el={el} /> }
          </li>
        ))}
      </ul>
    </section>
  )
}

const Close = ({e}) => {
  const {toggleDrawer} = e;

  return (
    <div css={css`
      width: 100%;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
    `}>
      <button onClick={toggleDrawer} aria-label="Close Drawer" css={css`
        width: 2em;
        background: ${color.grey200};
        height: .5em;
        border-radius: 5px;
        border: none;
        cursor: pointer;
      `} />
    </div>
  )
}

const IntroElement = () => {
  const buttons = navigation?.mobile?.intro?.buttons;
  
  return (
    <section css={css`
      padding: 2em;
    `}>
      <div css={css`
        width: 6rem;
        svg {
          height: auto;
          width: 100%;
          fill: ${color.main_dark};
        }
      `}>
        <SALogo />
      </div>
      <div 
        dangerouslySetInnerHTML={{__html: navigation.mobile.intro.content}}
        css={css`
          font-size: .85em;
          margin: 2em 0;
          p {
            margin-bottom: .5em;
          }
        `}
      />
      <div css={css`
        a, button {
          &:not(:last-child) {
            margin-bottom: 1em;
          }
        }
      `}>
        { buttons.filter(el => !(el.active !== undefined && el.active === false)).map((button, index) => (
          button.raw !== undefined ? <React.Fragment key={index}>{button.raw}</React.Fragment>
          : button.title !== undefined ? (
            <IconButton
              key={index}
              name={button.title}
              icon={button.icon}
              url={button.url}
              css={css`
                width: 100%;
                display: block;
                padding: .475em 1em;
                &:before {
                  background: ${button.customColor?.background && button.customColor.background.length > 1 ? button.customColor.background : color.plue_light};
                }
              `}
            />
          ) : null
        )) }
      </div>
      <PlueLoginButton css={css`
        padding: 0.35rem 0.8rem;
        margin-top: 1em;
        color: ${color.gray700};
        svg {
          stroke: ${color.gray700};  
        }
        &:before {
          background: none;
          border: 1px solid ${color.gray400};
        }
      `} />
      {Flags.features.allAccess && (
        <div css={css`
          p, a {
            color: ${color.gray400};
          }
          p {
            padding: 0.5em 1em;
            font-size: 0.75em;
            font-weight: 500;
          }
        `}>
          <p>Please register for the event in order to receive an email containing the passcode or get in touch with Hannah <a href="mailto:hannah@pnptc.com">(hannah@pnptc.com)</a>.</p>
        </div>
      )}
    </section>
  )
}

const PlueDrawerElement = ({e, drawer}) => {
  const {drawerActive} = e;
  return (
    <>
      <aside
        css={css`
          background: ${color.main_light};
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          overflow: hidden;
          transition: height 0.6s cubic-bezier(0.42, 0, 0.18, 0.99);
          position: fixed;
          bottom: 4.3rem;
          width: 100%;
          z-index: 9999;
          height: ${drawerActive ? "calc(100% - 8rem)" : 0};
          ${mq[2]} {
            display: none;
          }
        `}
      >
        <Close e={e}  />
        <nav
          ref={drawer}
          css={css`
            overflow: scroll;
            height: 100%;
            padding-bottom: 3em;
          `}
        > 
        
          <IntroElement />
          <Navigation />

        </nav>

      </aside>
    </>
  )
}

const PlueDrawer = (e) => {
  const {drawerActive, toggleDrawer} = e;
  const drawer = useRef(null)
  useEffect(() => {

    if (drawerActive) {
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
      disableBodyScroll(drawer);
    } else {
      document.getElementsByTagName("html")[0].style.overflow = "auto";
      enableBodyScroll(drawer);
    }

  }, [drawerActive])
  return (
    <>
      <div
        onClick={toggleDrawer}
        onKeyDown={toggleDrawer}
        role="button"
        aria-label="Close Menu Drawer"
        tabIndex="0"
        css={css`
          background: black;
          opacity: 0.7;
          width: 100%;
          height: 100%;
          position: fixed;
          top: 0;
          left: 0;
          z-index: 98;
          display: ${drawerActive ? "block" : "none"};
          &:hover {
            cursor: pointer;
          }
          ${mq[1]} {
            display: none;
          }
        `}
      ></div>
      <PlueDrawerElement e={e} drawer={drawer} />
    </>
  )
}


export default PlueDrawer;